<template>
    <svg width="160px" height="40px" viewBox="0 0 160 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <radialGradient cx="100%" cy="23.2866175%" fx="100%" fy="23.2866175%" r="109.44436%" gradientTransform="translate(1.000000,0.232866),scale(1.000000,0.888432),rotate(135.124934),translate(-1.000000,-0.232866)" id="radialGradient-l2eugolqqx-1">
                <stop stop-color="#BBEA70" offset="0%"></stop>
                <stop stop-color="#51B387" offset="100%"></stop>
            </radialGradient>
        </defs>
        <g id="Website" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Template" transform="translate(-90.000000, -60.000000)">
                <g id="Navbar" transform="translate(60.000000, 39.000000)">
                    <g id="logo" transform="translate(30.000000, 21.000000)">
                        <g id="Logomark">
                            <path d="M27.0569516,23.0874896 L27.056,29.887 L27.0616781,29.9873552 C27.132242,30.7171654 27.7484718,31.289613 28.5025201,31.3016256 L35.535109,31.3001716 L35.535,38.605 L35.5319894,38.685537 C35.462544,39.4165057 34.8552282,39.9896118 34.112701,39.9998603 L20.235754,40 C18.5083804,39.9944955 16.8307435,39.7759677 15.2296677,39.3702877 C14.0276035,39.06589 12.8691288,38.6558063 11.7643026,38.1504952 L27.0569516,23.0874896 Z M34.0750582,0 C34.8826222,0 35.5372822,0.651222182 35.5372822,1.45454545 L35.534,1.394 L35.5349414,9.17101176 L14.214106,9.17025809 C13.4203537,9.1918619 12.7836098,9.834563 12.7836098,10.6242424 L12.784,10.604 L12.784,16.086 L12.7903471,16.2067491 C12.8616643,16.9443509 13.4903609,17.5212121 14.2553868,17.5212121 L20.8385744,17.5213058 L12.7845763,25.4543603 L4.98932103,33.1325575 C4.13429626,32.1654206 3.37203888,31.1168174 2.71596105,29.9994083 C1.80728766,28.4526498 1.10259077,26.7737838 0.639312641,24.9996904 C0.221859369,23.4017402 0,21.7267274 0,20.0005229 L5.07650003,19.9999725 L0,19.9999725 C0,18.273768 0.221859369,16.5982047 0.639871481,14.9997041 C1.10370845,13.2261611 1.8078465,11.5472952 2.71707873,9.99998624 C3.39495131,8.84624753 4.18626835,7.76516793 5.07650003,6.77160955 C5.63813395,6.14464944 6.23888665,5.55291799 6.87540509,4.9997179 C7.88411079,4.12340491 8.981672,3.34397074 10.1530001,2.67627749 C11.7238985,1.78124927 13.4283597,1.08768497 15.2295001,0.631363693 C16.8518118,0.220179143 18.5518022,0.00165134357 20.3043236,0.00165134357 Z" id="c" fill="url(#radialGradient-l2eugolqqx-1)"></path>
                            <path d="M42.5512804,9.16988959 C43.3535914,9.18266091 44,9.8289058 44,10.6242424 L43.997109,10.576879 L43.997109,29.894879 L43.9932573,29.9885673 C43.9238003,30.7063656 43.3260759,31.2719386 42.5884287,31.3017909 L35.535109,31.3001716 L35.535109,9.17106681 Z" id="Combined-Shape" fill="#BBEA70"></path>
                        </g>
                        <g id="CI-Method" transform="translate(53.654660, 12.336557)" fill="#233544" fill-rule="nonzero">
                            <path d="M7.65878571,16.8846429 C6.19908259,16.8846429 4.89132171,16.5535714 3.73550307,15.8914286 C2.57968443,15.2292857 1.66814593,14.2695522 1.00088756,13.012228 C0.333629185,11.7549037 0,10.2352801 0,8.45335714 C0,6.65970871 0.336962891,5.13238309 1.01088867,3.8713803 C1.68481445,2.61037751 2.60077874,1.64989676 3.75878153,0.989938058 C4.91678432,0.329979353 6.21678571,0 7.65878571,0 C8.61073103,0 9.49577232,0.133520647 10.3139096,0.400561942 C11.1320469,0.667603237 11.8563518,1.05753181 12.4868245,1.57034766 C13.1172972,2.0831635 13.6304579,2.70998633 14.0263066,3.45081613 C14.4221554,4.19164593 14.6760056,5.03659654 14.7878571,5.98566797 L11.2785,5.98566797 C11.2137801,5.53101953 11.0824436,5.12419252 10.8844905,4.76518694 C10.6865374,4.40618136 10.432716,4.10085993 10.1230262,3.84922266 C9.8133365,3.59758538 9.45800949,3.40517885 9.0570452,3.27200307 C8.65608092,3.13882729 8.21649163,3.0722394 7.73827734,3.0722394 C6.8950798,3.0722394 6.15603181,3.28229157 5.52113337,3.70239593 C4.88623493,4.12250028 4.39439844,4.73314314 4.04562388,5.5343245 C3.69684933,6.33550586 3.52246205,7.30851674 3.52246205,8.45335714 C3.52246205,9.62164844 3.69903348,10.6045742 4.05217634,11.4021345 C4.4053192,12.1996948 4.89715569,12.8007963 5.52768583,13.2054392 C6.15821596,13.610082 6.89065402,13.8124035 7.725,13.8124035 C8.18999442,13.8124035 8.61964007,13.7513334 9.01393694,13.6291934 C9.40823382,13.5070533 9.76137667,13.3268033 10.0733655,13.0884434 C10.3853544,12.8500834 10.6447224,12.5601948 10.8514696,12.2187773 C11.0582168,11.8773599 11.2005603,11.4859369 11.2785,11.0445084 L14.7878571,11.0445084 C14.698077,11.8022941 14.474029,12.5310248 14.1157132,13.2307006 C13.7573973,13.9303764 13.2740388,14.5542679 12.6656376,15.102375 C12.0572363,15.6504821 11.3340234,16.0845536 10.4959989,16.4045893 C9.65797433,16.724625 8.71223661,16.8846429 7.65878571,16.8846429 Z" id="Path"></path>
                            <polygon id="Path" points="20.0230265 0.220714286 20.0230265 16.6639286 16.5535014 16.6639286 16.5535014 0.220714286"></polygon>
                            <polygon id="Path" points="26.6445628 0.220714286 30.9352141 0.220714286 35.4598569 11.2653951 35.6452224 11.2653951 40.1831426 0.220714286 44.4915545 0.220714286 44.4915545 16.6639286 41.0880712 16.6639286 41.0880712 5.95928571 40.9601259 5.95928571 36.6913737 16.5844369 34.387151 16.5844369 30.1582308 5.93273103 30.0170081 5.93273103 30.0170081 16.6639286 26.6445628 16.6639286"></polygon>
                            <path d="M52.1678596,16.902231 C50.9039255,16.902231 49.8132578,16.6451046 48.8958566,16.1308518 C47.9784553,15.6165991 47.2747561,14.8842185 46.7847589,13.9337101 C46.2947617,12.9832017 46.0497631,11.8568404 46.0497631,10.5546261 C46.0497631,9.28471429 46.2943881,8.17117048 46.7836381,7.2139947 C47.2728881,6.25681892 47.9633387,5.51044252 48.8549899,4.97486551 C49.7466412,4.4392885 50.7964997,4.1715 52.0045656,4.1715 C52.8212084,4.1715 53.5822991,4.30134208 54.2878376,4.56102623 C54.9933761,4.82071038 55.6110025,5.21063895 56.1407168,5.73081194 C56.6704311,6.25098493 57.0831783,6.90246568 57.3789584,7.68525419 C57.6747385,8.46804269 57.8226286,9.38472545 57.8226286,10.4353025 L57.8226286,11.371269 L47.4093976,11.371269 L47.4093976,9.26120592 L56.1672715,9.26120592 L54.5738178,9.81299163 C54.5738178,9.18315123 54.4781462,8.63760184 54.286803,8.17634347 C54.0954598,7.7150851 53.8114626,7.35679799 53.4348114,7.10148214 C53.0581601,6.84616629 52.5902631,6.71850837 52.0311202,6.71850837 C51.4719774,6.71850837 50.9981601,6.84688477 50.6096685,7.10363756 C50.2211769,7.36039035 49.9253968,7.71060184 49.7223281,8.15427204 C49.5192595,8.59794224 49.4177252,9.10670592 49.4177252,9.68056306 L49.4177252,11.1726261 C49.4177252,11.8376429 49.5343474,12.4077927 49.7675918,12.8830756 C50.0008362,13.3583585 50.3286027,13.7225371 50.7508912,13.9756113 C51.1731797,14.2286855 51.6632056,14.3552227 52.220969,14.3552227 C52.5990572,14.3552227 52.9429749,14.3011649 53.2527221,14.1930494 C53.5624693,14.0849339 53.8291657,13.9241975 54.0528114,13.7108404 C54.276457,13.4974833 54.4457,13.2369944 54.5605404,12.9293739 L57.7165823,13.1412941 C57.5562196,13.9005742 57.2321604,14.5623722 56.7444048,15.1266881 C56.2566493,15.6910039 55.6261479,16.1280067 54.8529007,16.4376964 C54.0796534,16.7473862 53.1846398,16.902231 52.1678596,16.902231 Z" id="Path"></path>
                            <path d="M65.8257011,4.326 L65.8257011,6.90387388 L58.400907,6.90387388 L58.400907,4.326 L65.8257011,4.326 Z M60.0826464,1.3905 L63.4949238,1.3905 L63.4949238,12.8676429 C63.4949238,13.2781484 63.5876353,13.5816306 63.7730583,13.7780893 C63.9584813,13.974548 64.2542039,14.0727773 64.6602262,14.0727773 C64.7897809,14.0727773 64.9358605,14.0609944 65.0984648,14.0374286 C65.2610692,14.0138627 65.3989868,13.9873655 65.5122179,13.9579369 L66.0552095,16.5182227 C65.6799952,16.6285798 65.3180295,16.7095084 64.9693125,16.7610084 C64.6205954,16.8125084 64.2755281,16.8382584 63.9341107,16.8382584 C62.7069623,16.8382584 61.7582645,16.5307241 61.0880173,15.9156554 C60.4177701,15.3005868 60.0826464,14.4361512 60.0826464,13.3223488 L60.0826464,1.3905 Z"></path>
                            <path d="M70.9837579,9.5393404 L70.9837579,16.6639286 L67.5714805,16.6639286 L67.5714805,0.220714286 L70.8777116,0.220714286 L70.8777116,6.51986551 L71.0279007,6.51986551 C71.3103461,5.79150837 71.7613159,5.21802483 72.3808103,4.7994149 C73.0003048,4.38080497 73.7779778,4.1715 74.7138293,4.1715 C75.5701318,4.1715 76.3179451,4.35764146 76.9572693,4.72992439 C77.5965935,5.10220731 78.0939479,5.63450809 78.4493324,6.32682673 C78.8047169,7.01914537 78.9824091,7.84863449 78.9824091,8.81529408 L78.9824091,16.6639286 L75.5613377,16.6639286 L75.5613377,9.4245 C75.5613377,8.66372545 75.3682127,8.07110184 74.9819627,7.64662919 C74.5957127,7.22215653 74.0531234,7.0099202 73.3541948,7.0099202 C72.887706,7.0099202 72.4767981,7.11033371 72.1214711,7.31116071 C71.766144,7.51198772 71.4876934,7.79963477 71.2861192,8.17410184 C71.084545,8.54856892 70.9837579,9.00364844 70.9837579,9.5393404 Z" id="Path"></path>
                            <path d="M86.5441393,16.902231 C85.2962989,16.902231 84.2188223,16.6362818 83.3117096,16.1043834 C82.4045969,15.5724849 81.7064155,14.8294135 81.2171655,13.8751691 C80.7279155,12.9209247 80.4832905,11.8110882 80.4832905,10.5456596 C80.4832905,9.27149442 80.7279155,8.15654241 81.2171655,7.20080357 C81.7064155,6.24506473 82.4045969,5.50124609 83.3117096,4.96934766 C84.2188223,4.43744922 85.2962989,4.1715 86.5441393,4.1715 C87.7947386,4.1715 88.8736234,4.43744922 89.7807936,4.96934766 C90.6879638,5.50124609 91.3868923,6.24506473 91.8775793,7.20080357 C92.3682662,8.15654241 92.6136097,9.27149442 92.6136097,10.5456596 C92.6136097,11.8110882 92.3682662,12.9209247 91.8775793,13.8751691 C91.3868923,14.8294135 90.6879638,15.5724849 89.7807936,16.1043834 C88.8736234,16.6362818 87.7947386,16.902231 86.5441393,16.902231 Z M86.5572442,14.2360714 C87.1282274,14.2360714 87.6042576,14.0764272 87.9853346,13.7571387 C88.3664116,13.4378502 88.6540874,12.9982609 88.8483619,12.4383708 C89.0426365,11.8784807 89.1397738,11.2432087 89.1397738,10.5325547 C89.1397738,9.81155469 89.0426365,9.17366741 88.8483619,8.61889286 C88.6540874,8.0641183 88.3664116,7.62855246 87.9853346,7.31219531 C87.6042576,6.99583817 87.1282274,6.8376596 86.5572442,6.8376596 C85.9878703,6.8376596 85.5093112,6.99583817 85.1215667,7.31219531 C84.7338223,7.62855246 84.4428416,8.0641183 84.2486245,8.61889286 C84.0544074,9.17366741 83.9572989,9.81155469 83.9572989,10.5325547 C83.9572989,11.2432087 84.0544074,11.8784807 84.2486245,12.4383708 C84.4428416,12.9982609 84.7338223,13.4378502 85.1215667,13.7571387 C85.5093112,14.0764272 85.9878703,14.2360714 86.5572442,14.2360714 Z"></path>
                            <path d="M98.6170787,16.8625714 C97.6798477,16.8625714 96.8319657,16.6201593 96.0734328,16.1353351 C95.3148998,15.6505109 94.7149191,14.9357759 94.2734905,13.9911303 C93.8320619,13.0464847 93.6113477,11.8877059 93.6113477,10.5147941 C93.6113477,9.10072824 93.8394191,7.92395898 94.2955619,6.98448633 C94.7517048,6.04501367 95.3579219,5.34131445 96.1142132,4.87338867 C96.8705045,4.40546289 97.6989302,4.1715 98.5994905,4.1715 C99.2895675,4.1715 99.8659824,4.287375 100.328735,4.519125 C100.791488,4.750875 101.166702,5.03964286 101.454378,5.38542857 C101.742054,5.73121429 101.96021,6.07257422 102.108848,6.40950837 L102.210411,6.40950837 L102.210411,0.220714286 L105.622516,0.220714286 L105.622516,16.6639286 L102.263348,16.6639286 L102.263348,14.6862941 L102.108848,14.6862941 C101.951359,15.0262171 101.726219,15.3628064 101.433427,15.6960619 C101.140636,16.0293175 100.764703,16.3070497 100.305629,16.5292584 C99.846555,16.7514671 99.2837048,16.8625714 98.6170787,16.8625714 Z M99.6940954,14.1434749 C100.243007,14.1434749 100.710215,13.9915326 101.095717,13.6876482 C101.48122,13.3837637 101.775879,12.9596071 101.979695,12.4151786 C102.183511,11.87075 102.285419,11.2343571 102.285419,10.506 C102.285419,9.76879129 102.184977,9.12981194 101.984092,8.58906194 C101.783208,8.04831194 101.489296,7.63004687 101.102356,7.33426674 C100.715416,7.03848661 100.245996,6.89059654 99.6940954,6.89059654 C99.1335731,6.89059654 98.661279,7.04363086 98.2772132,7.3496995 C97.8931473,7.65576814 97.602885,8.07992467 97.4064263,8.62216908 C97.2099676,9.1644135 97.1117383,9.79235714 97.1117383,10.506 C97.1117383,11.2181484 97.2107148,11.8482762 97.408668,12.3963834 C97.6066211,12.9444905 97.8976018,13.3726992 98.2816102,13.6810095 C98.6656186,13.9893198 99.136447,14.1434749 99.6940954,14.1434749 Z"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>
